import _ from "lodash";
import { Link as RRLink } from "react-router";
import type { LinkProps as RRLinkProps, To } from "react-router";
import { matchPath, useLocation } from "react-router";

export interface LinkProps extends Omit<RRLinkProps, "to"> {
  to?:
    | To
    | (() => void)
    | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void);
  disabled?: boolean;
  external?: boolean;
}

export const useMatch = (
  path: string | undefined,
  to: LinkProps["to"],
  exact = true
) => {
  const location = useLocation();
  if (_.isFunction(to)) return false;
  let adjustedPath =
    path || (_.isObject(to) && "pathname" in to ? to.pathname : (to as string));
  if (!adjustedPath) {
    return false;
  }
  adjustedPath = exact ? adjustedPath : `${adjustedPath}/*`;
  return !!matchPath(adjustedPath, location.pathname);
};

export default function Link({ external, state, ...props }: LinkProps) {
  const { disabled, onClick } = props;
  // const updateAvailable = useContext(UpdateAvailableContext);

  const handleFunc = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (!disabled && _.isFunction(props.to)) {
      props.to(event);
    }
  };

  const { children, ...allProps } = props;

  // Setting a target will make React Router treat it like a normal <a ... /> link
  // if (updateAvailable && !allProps.target) {
  //   // TODO: Figure out pushing new version
  //   allProps.target = "_top";
  // }
  const { to, ...rest } = allProps;

  if (_.isFunction(to)) {
    return (
      <a
        href="#"
        {...rest}
        onClick={(e) => {
          // @headlessui/react passes it's own onClick handler that we need to call
          props.onClick?.(e);
          handleFunc(e);
        }}
      >
        {children}
      </a>
    );
  }
  if (external)
    return (
      <a
        {...rest}
        href={to as string}
        onClick={disabled ? handleFunc : onClick}
      >
        {children}
      </a>
    );

  return (
    <RRLink
      to={to!}
      {...rest}
      state={state}
      onClick={disabled ? handleFunc : onClick}
    >
      {children}
    </RRLink>
  );
}
